// Fonts
$font-helvetica-now-regular:                "Helvetica Now Regular", Helvetica, Arial, sans-serif !default;
$font-helvetica-now-medium:                 "Helvetica Now Medium", Helvetica, Arial, sans-serif !default;
$font-helvetica-now-display:                "Helvetica Now Display", Helvetica, Arial, sans-serif !default;

// Breakpoints
$desktop: 					1439px; // No utilizar es por defecto
$desktop-small: 			1279px;
$tablet: 					959px ;
$phone: 					639px;
$phone-small:				479px; // No utilizar