/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Helvetica Now Medium";
        font-style: normal;
        src: url("./assets/fonts/HelveticaNowTextMedium.otf") format("opentype");
        font-display: swap;
    }
    @font-face {
        font-family: "Helvetica Now Regular";
        font-style: normal;
        src: url("./assets/fonts/HelveticaNowText.otf") format("opentype");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Helvetica Now Display";
        font-style: normal;
        src: url("./assets/fonts/HelveticaNowDisplay.otf") format("opentype");
        font-display: swap;
    }
}
/** sobre escritura de estilos de coockiebot **/
#CybotCookiebotDialog *{
    font-family: "Helvetica Now Regular", Helvetica, Arial, sans-serif !important;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-weight: 400;
  }

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type),
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type), #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton, #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
    padding: 8px 16px 10px;
    line-height: 18px;
    transition: all .3s;
    width: auto !important;
    border: 1px solid;
    font-family: inherit;
    font-weight: normal !important;
    height: 40px;
    border-radius: 8px;
    font-size: 15px;
}
#CybotCookiebotDialog h2,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink{
    font-family: inherit;
}

.CybotCookiebotDialogBodyBottomWrapper{
    display: none !important;
}
#CybotCookiebotDialogHeader{
    display: none !important;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper{
    max-width: 1400px !important;
}

button#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    margin-right: 8px;
}

@media only screen and (max-width: 1280px) {
    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        margin-right: 8px;
    }
}

@media only screen and (max-width: 600px) {
    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        margin-right: 0px;
    }
}
#CybotCookiebotDialogBodyLevelButtonNecessaryInline,
#CybotCookiebotDialogBodyLevelButtonPreferencesInline,
#CybotCookiebotDialogBodyLevelButtonStatisticsInline,
#CybotCookiebotDialogBodyLevelButtonMarketingInline
{
  height: 100% !important;
  width: 100% !important;
  opacity: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}
/**fin de sobre escritura de estilos de cookiebot **/

// estilos del scroll
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb{
    background-color: rgba(19, 29, 52, 0.10);
    border-radius: 8px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    border: 0;
}

//animiaciones
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
.fade-in {
    animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeInLoader {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.94;
  }
}

.fade-in-loader {
  animation: fadeInLoader 0.3s ease-out forwards;
}

@keyframes slideDownFadeIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideUpFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  
  .slide-down-fade-in {
    animation: slideDownFadeIn 0.5s ease-out forwards;
  }
  
  .slide-up-fade-out {
    animation: slideUpFadeOut 0.5s ease-out forwards;
  }

  @keyframes expandHeight {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 100px;
      opacity: 1;
    }
  }
  
  .expand-height {
    animation: expandHeight 0.5s ease-out forwards;
    overflow: hidden;
  }

.hide {display: none;}

// Estilos chat genesys
@import "./assets/sass/chat-genesys.scss";


html {scroll-behavior: smooth;}

.background-overlay::before 
{
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Ajusta el color y opacidad del velado */
    z-index: 1;
    pointer-events: none;
}