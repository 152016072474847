@import 'src/assets/sass/inc/_variables.scss';
@import 'src/assets/sass/inc/_colors.scss';
@import 'src/assets/sass/inc/_titles.scss';

/* fonts for chat */
$font-regular:  "Helvetica Now Regular", Helvetica, Arial, sans-serif;
$font-medium:  "Helvetica Now Medium", Helvetica, Arial, sans-serif;
$font-display: "Helvetica Now Display", Helvetica, Arial, sans-serif;

.text-chat {
    font-family: $font-regular;
    color: $color-content-high;
}
.text-chat-low-xs {
    font-family: $font-regular;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: -0.5px !important;
    font-weight: 400 !important;
}
.text-chat-low-s {
    font-family: $font-regular;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.5px !important;
    font-weight: 400 !important;
}
.text-chat-low-m {
    font-family: $font-regular;
    font-size: 16px !important; 
    line-height: 22px !important;
    letter-spacing: -0.6px !important;
    font-weight: 400 !important;
}

.low-l-chat {
    font-family: $font-regular;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: -0.8px !important;
    font-weight: 400 !important;
}
.high-xs-chat {
    font-family: $font-regular;
    font-size:  24px !important;
    line-height: 30px !important;
    letter-spacing: -1.2px !important;
    font-weight: 400 !important;
    @media screen and ( max-width: $desktop-small) {
        font-size: 20px !important;
        line-height: 28px !important;
    }
}
.text-chat-mid-s{
    font-family: $font-medium !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    letter-spacing: -0.4px !important;
}



.btn-genesyschat {
    display: flex;
    position: fixed;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    right: 24px;
    bottom: 85px;
    z-index: 51;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #0080F6;
    box-shadow: 0px 6px 8px 0px rgba(19, 29, 52, 0.14);
}
.btn-genesyschat img:hover {
    cursor: pointer;
}

.cx-widget.cx-theme-light .cx-button-group button.cx-btn-default:focus{
    border-color: #0080F6 !important;
}
.cx-webchat div.cx-input-container .cx-textarea-cell{
    padding: 0px 16px !important;
    border-radius: 8px !important;
    border: 1px solid #0080F6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.cx-webchat div.cx-input-container .cx-message-input{
    border: none !important;
    margin: 10px 0px !important;
    padding-right: 32px;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant:first-child .cx-bubble-arrow{
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-them:first-child .cx-avatar-wrapper{
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-you:last-child .cx-bubble{
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 0px !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-you:first-child .cx-bubble{
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.cx-message.cx-participant.cx-them.cx-Agent.cx-agent.cx-NewTextBubble .cx-bubble{
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-Agent.cx-agent:last-child .cx-bubble{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 12px !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-Agent.cx-agent:first-child .cx-bubble{
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.cx-bubble span.cx-name {
    display: none !important;
}
.cx-emoji-hasmenu{
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message .cx-name {
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant:first-child .cx-name{
    display: none !important;
    color: transparent;
    width: 0px;
}
// .cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant{
//     margin-bottom: 12px !important;
// }
.cx-widget.cx-desktop .cx-input-focus{
    border-width: 0px !important;
    box-shadow: none !important;
    transition: none !important;
    border-color: none !important;
}
.cx-webchat.cx-mobile .cx-input-container{
    padding: 12px 16px !important;
    position: fixed !important;
    bottom: 0px;
}
.cx-menu-cell{
    display: none !important;
}
.cx-webchat.cx-mobile .cx-send{
    right: 0px !important;
}
.cx-webchat .cx-transcript .cx-message.cx-them{
    padding: 0px !important;
}
.cx-common-screen-reader{
    display: none !important;
}
.cx-toaster.cx-mobile{
    right: 16px !important;
    left: 16px !important;
    width: auto !important;
}

.cx-widget {
    font-family: $font-helvetica-now-regular !important;
    border-radius: 16px;
    transition: none !important;
    .cx-control-label {
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        color: #131D34 !important;
        @extend .text;
        @extend .low-s;
    }
    .cx-input {
        &.cx-form-control {
            content: "";
            display: inline-block;
            /* width: 100%; */
            @extend .text-chat-low-s;
            color: $color-content-high;
            height: 40px;
            border-radius: 8px;
            background-color: #fff;
            padding: 0 16px;
            border: 1px solid rgba(19, 29, 52, 0.10) !important;

        }
        &.cx-form-control::placeholder{
            color: $color-content-low !important;
            letter-spacing: -0.5px;
            opacity: 1;
        }
    }
    &.cx-theme-light{
        background-color: #fff !important;
        .cx-smokescreen{
            background-color: rgba(19, 29, 52, 0.25) !important;
            opacity: inherit !important;
        }
    }

    &.cx-toaster{
        bottom: 90px !important;
        right: 24px !important;
        border-radius: 16px;
        width: 360px;
        .cx-titlebar{
            .cx-icon{
                width: 24px !important;
                height: 24px !important;
                background-image: url('../../assets/images/icons/chat/no_headphone.svg');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .cx-body{
            padding: 0px 16px 20px 16px !important;
            @extend .text;
            @extend .low-m;
            color: $color-content-high;
            a{
                color: #0080F6;
                text-decoration: none;
            }
            a:hover{
                opacity: 0.75;
                color: #0080F6;
            }
            a:active{
                opacity: 0.50;
                color: #0080F6;
            }
        }
        .cx-button-container{
            display: none;
        }
    }
    .cx-alert>.cx-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30% 16px !important;
        align-items: flex-start;
        padding: 16px !important;
        border-radius: 16px !important;
        gap: 16px;
        border: none !important;
        p{
            color: $color-content-high !important;
            text-align: initial;
            @extend .text-chat-mid-s;
            margin-bottom: 0px !important;
        }
    }
    #cx_input{
        min-height: 20px !important;
        border: none !important;
    }
}
.cx-common-container {
    &.cx-loading {
        .cx-loading-screen,
        .cx-smokescreen {
            display: block;
        }
    }

    &.cx-waiting .cx-loading-screen {
        display: block;
        pointer-events: none;
        background: none;
    }
}
.cx-webchat.cx-webchat.cx-desktop.cx-minimized{
    bottom: 24px !important;
    right: 88px !important;
    width: 255px !important;
    .cx-titlebar{
        padding: 12px 16px !important;
        border-radius: 8px;
        min-height: 48px;
    }
    .cx-button-group.cx-buttons-window-control{
        top: 12px !important;
        .cx-icon.cx-button-maximize {
            &:before {display: none}
        }
    }
}
.cx-webchat.cx-mobile.cx-minimized{
    border-radius: 8px 8px 0px 0px !important;
    border: 1px solid rgba(19, 29, 52, 0.10) !important;
    .cx-button-group.cx-buttons-window-control{
        top: 17px !important;
    }
}
.cx-loading-screen {
    background: #fff !important;
    opacity: 0.94 !important;
    border-radius: 16px !important;
    .cx-loading-icon{
        width: 32px;
        height: 32px;
        svg{
            display: none !important;
        }
        background-image: url("../../assets/images/icons/loadingIM.gif");
        background-size: contain;
        background-repeat: no-repeat;
        &:before {display: none}
    }
}
.cx-history-loading.cx-loading-container{
    .cx-loading-icon.cx-icon{
        width: 24px;
        height: 24px;
        svg{
            display: none !important;
        }
        background-image: url("../../assets/images/icons/chat/loader-chat.gif");
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.cx-loading-container {
    display: none;
    position: relative;

    .cx-loading-icon {
        display: inline-block;
        height: 32px !important;
        width: 32px !important;
        left: -25px;
        margin-left: 50%;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.cx-loading-screen .cx-loading-icon {
    display: inline-block;
    height: 32px !important;
    width: 32px !important;
    left: -25px;
    margin-left: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@-webkit-keyframes cxloadingspin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
    }
}

@keyframes cxloadingspin {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(1turn);
    }
}

.cx-common-container,
.cx-drop-shadow {
    //-webkit-box-shadow: 0 0 21px 2px rgba(0, 0, 0, 0.2);
    //box-shadow: 0 0 21px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 6px 8px 0px rgba(19, 29, 52, 0.14) imo !important;
    box-shadow: 0px 6px 8px 0px rgba(19, 29, 52, 0.14) !important;
}

.cx-common-container {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    button,
    form {
        outline: none;
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        width: 0px !important;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb{
        background-color: transparent;
    }

    .cx-titlebar {
        clear: both;
        border-width: 0 0 1px;
        border-style: solid;
        padding: 20px 16px !important;
        background-color: #fff !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 16px;
        border: none !important;
        .cx-icon {
            float: left;
            width: 20px !important;
            height: 20px !important;
            margin: 0px !important;
            svg{
                display: none !important;
            }
            background-image: url("../../assets/images/icons/chat/logo.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        .cx-title {
            padding-top: 1px;
            @extend .text-chat-mid-s;
        }

        .cx-close {
            float: right;

            .cx-button-close {
                width: 24px;
                height: 24px;
                svg{
                    display: none !important;
                }
            }
        }

    }

    .cx-body {
        padding: 24px 24px 0;
        overflow: auto;
        clear: both;
        position: relative;

        ::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
        ::-webkit-scrollbar-thumb{
            background-color: transparent !important;
        }
    }

    .cx-button-container {
        font-size: 0.75em;
        line-height: 1em;

        .cx-button-group {
            .cx-btn {
                margin: 12px 0 0;
                border: none;
            }

            .cx-text {
                margin: 20px 0;
                line-height: 1em;
            }
        }
    }

    .cx-dialog-container,
    .cx-smokescreen {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: none;
        border-radius: 16px !important;
    }

    .cx-dialog-container {
        padding: 48px 96px;
        font-size: 0.875em;

        .cx-dialog {
            border: 1px solid;
            padding: 24px;
            top: 28px;
            position: relative;
            display: none;
        }
    }

    .cx-footer {
        padding: 14px 24px 18px;
        background-color: inherit;
        display: none !important;

        .cx-powered-by {
            font-size: 0.625em;
            display: none;
        }
    }

    .cx-branding {
        padding: 10px 12px 26px 15px;

        .cx-icon {
            float: left;
            width: 16px;
            height: 16px;
        }
    }

    .cx-email-link {
        font-weight: 700;
    }

    .cx-powered-by .cx-icon {
        position: relative;
        top: 2px;

        svg {
            width: 59px;
            height: 14px;
        }
    }

    .cx-buttons-window-control {
        position: absolute;
        top: 18px !important;
        display: flex;
        flex-direction: row;
        gap: 12px;

        button {
            display: none;
            padding: 0;
            background-color: transparent;
            border: 0;
            width: 24px;
            height: 24px;
            padding: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;

            &:focus {
                border: none !important;
                border-color: transparent !important;
            }
        }
    }
}

.cx-footer .cx-button-group .cx-button-group {
    margin-bottom: 0;
}

.cx-button-group {
    overflow: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
}

.cx-buttons-single {
    text-align: center;

    .cx-btn-default,
    .cx-btn-primary {
        float: none;
    }
}

.cx-buttons-binary {
    position: relative;
    width: 100%;
    .cx-btn-primary {
        float: right;
    }

    .cx-btn-default {
        float: left;
        border-color: #0080F6;
    }
}

.cx-buttons-primary {
    .cx-btn-primary {
        float: left;
    }

    .cx-text {
        float: left;
        margin-left: 16px;
    }
}

.cx-buttons-default {
    .cx-btn-default {
        float: left;
    }

    .cx-text {
        float: right;
        margin-right: 16px;
    }
}

.cx-common-container {
    &.cx-close .cx-buttons-window-control button.cx-button-close {
        display: inline-block;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        svg{
            display: none !important;
        }
        background-image: url('../../assets/images/icons/chat/close.svg');
        background-size: contain;
        background-repeat: no-repeat;
        &:before {display: none;}
    }

    &.cx-minimize .cx-buttons-window-control button {
        &.cx-button-maximize{
            display: inline-block;
            margin-right: 5px;
            width: 24px;
            height: 24px;
            svg{
                display: none !important;
            }
            background-image: url('../../assets/images/icons/chat/maximize.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
        &.cx-button-minimize {
            display: inline-block;
            svg{
                display: none !important;
            }
            width: 24px;
            height: 24px;
            background-image: url('../../assets/images/icons/chat/minimized.svg');
            background-size: contain;
            background-repeat: no-repeat;
            &:before {display: none}
        }

    }

    &.cx-mobile {
        .cx-btn {
            padding: 5px 14px;
        }

        .cx-titlebar {
            padding: 20px 16px !important;

            .cx-icon {
                margin-left: 13px;
            }
        }
        .cx-body{
            .cx-transcript{
                padding: 16px !important;
                bottom: 20px;
            }

        }

        .cx-buttons-window-control {
            top: 7px;
            right: 16px !important;
            overflow: visible !important;

            > button {
                margin-right: 10px;
                padding: 4px;
                width: 24px;
                height: 24px;

                // &:focus {
                //     padding: 3px;
                // }
            }
        }

        .cx-button-container .cx-text {
            margin: 16px 9px 16px 0;
        }

        .cx-footer {
            border-width: 1px 0 0;
            border-style: solid;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .cx-dialog-container {
            padding: 20px;
        }
        .cx-form-wrapper{
            .cx-form.cx-form-horizontal{
                height: calc(100vh - 66px) !important;
            }
        }
        &.cx-minimized{
            .cx-titlebar{
                padding: 16px 16px !important;
            }
        }
    }
    button {
        &.cx-btn {
            &.cx-btn-default {
                &.i18n {
                    display: block;
                    background: #fff !important;
                    border: 1px solid #0080F6 !important;
                    color: $color-content-high !important;
                    border-radius: 8px !important;
                    height: 40px !important;
                    padding: 0px 16px !important;
                    width: inherit;
                    @extend .text-chat-low-m;
                }
                &:hover {
                    opacity: 0.75;
                }
            }
            &.cx-btn-primary {
                &.i18n {
                    display: block;
                    background: #0080F6 !important;
                    border: 1px solid #0080F6 !important;
                    color: #fff !important;
                    border-radius: 8px !important;
                    height: 40px !important;
                    padding: 0px 16px !important;
                    width: inherit;
                    @extend .text-chat-low-m;

                }
                &:hover {
                    opacity: 0.75;
                }
                &:focus{
                    outline: none !important;
                    border: none !important;
                    border-color: transparent !important;
                }
            }
        }

        &.cx-submit {
            //@extend .button-squared;
            &.cx-btn {
                font-weight: normal !important;
                &.cx-btn-primary {
                    &.i18n {
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        background: #0080F6;
                        border: none;
                        height: 40px;
                        align-items: center;
                    }
                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
        }
    }
}
.cx-webchat {
    width: 360px !important;
    &.cx-webchat {
        &.cx-desktop {
            bottom: 90px !important;
            right: 24px !important;
            animation: fadeIn 2s;
            -webkit-animation: fadeIn 2s;
            -moz-animation: fadeIn 2s;
            -o-animation: fadeIn 2s;
            -ms-animation: fadeIn 2s;
            border-radius: 16px;
        }
    }

    .cx-transcript{
        padding: 16px 16px 0px 16px !important;
        background-color: #f2f4f8 !important;
        .cx-message-group{
            margin-bottom: 12px !important;
        }
        .cx-message{
            min-height: auto !important;
        }
        .cx-message.cx-system {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: auto !important;
            margin: 0 !important;
            .cx-bubble {
                padding: 4px 8px !important;
                background-color:  #E0F0FF !important;
                border-radius: 8px !important;
                display: inline-flex !important;
                .cx-message-text {
                    text-align: center !important;
                    @extend .text-chat-low-xs;
                    color: $color-content-mid !important;
                    a{
                        color: #0080F6 !important;
                        text-decoration: none;
                    }
                }
                .cx-time{
                    display: none !important;
                }
            }
        }
        .cx-message.cx-date{
            padding: 4px 8px !important;
            border-radius: 8px !important;
            background-color: #FFF;
            border: none !important;
            @extend .text-chat-low-xs;
            color: $color-content-mid !important;
            margin: auto auto 16px auto !important;
            min-height: auto !important;
            line-height: 18px !important;
        }
        .cx-message.cx-you{
            .cx-bubble{
                padding: 8px 12px !important;
                background-color: #0080F6 !important;
                border-radius: 12px 12px 0px 12px !important;
                text-align: right;
                display: inline-flex !important;
                .cx-name{
                    display: none !important;
                }
                .cx-message-text {
                    text-align: left !important;
                    color: #fff !important;
                    margin-right: 16px !important;
                    @extend .text-chat-low-m;
                    max-width: 210px;
                }
                .cx-time{
                    color: #fff !important;
                    text-align: right !important;
                    align-self: end !important;
                    white-space: nowrap !important;
                    margin: 0px !important;
                    @extend .text-chat-low-xs;
                }
            }
            .cx-bubble::after{
                content: "";
                border-bottom-left-radius: 0px !important;
            }
        }
        .cx-message.cx-agent{
            .cx-avatar-wrapper{
                display: none !important;
            }
            .cx-bubble-arrow{
                display: none !important;
            }
            .cx-bubble{
                background-color: #fff !important;
                display: inline-flex;
                margin: 0px !important;
                padding: 8px 12px !important;
                .cx-name{
                    display: none !important;
                }
                .cx-message-text {
                    text-align: left !important;
                    color: $color-content-high !important;
                    margin-right: 16px !important;
                    @extend .text-chat-low-m;
                    max-width: 210px;
                }
                .cx-time{
                    text-align: right !important;
                    align-self: end !important;
                    white-space: nowrap !important;
                    margin: 0px !important;
                    @extend .text-chat-low-xs;
                    color: $color-content-low !important;
                }
            }
            .cx-bubble::after{
                content: "";
                border-bottom-right-radius: 0px !important;
            }
        }
        .cx-message.cx-agent-typing{
            //position: absolute;
            margin-bottom: 12px !important;
            .cx-avatar-wrapper{
                display: none !important;
            }
            .cx-bubble-arrow{
                display: none !important;
            }
            .cx-bubble{
                background-color: #fff !important;
                display: inline-flex;
                margin: 0px !important;
                border-radius: 12px 12px 12px 0px !important;
                padding: 8px 12px !important;
                .cx-name{
                    display: none !important;
                }
                .cx-message-text {
                    text-align: left !important;
                    color: $color-content-high !important;
                    @extend .text-chat-low-m;
                    max-width: 210px;
                }
                .cx-time{
                    display: none !important;
                }
            }
            .cx-bubble::after{
                content: "";
                border-bottom-right-radius: 0px !important;
            }
        }
        &:last-child{
            .cx-message-group{
                margin-bottom: 0px !important;
            }
        }
    }
    .cx-input-container{
        border-radius: 0px 0px 16px 16px !important;
        background-color: #fff !important;
        padding: 12px 16px !important;
        border-width: 0px !important;
        border-style: none !important;
        .cx-textare-cell{
            textarea{
                padding: 0 16px !important;
                @extend .text-chat-low-s;
                color: $color-content-high;
            }
        }
        .cx-send.cx-icon{
            svg{
                display: none !important;
            }
            width: 24px;
            height: 24px;
            background-image: url('../../assets/images/icons/chat/send.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 16px;
            margin-left: 8px;
            top: calc(100% - 32px);
            &:before {display: none;}
        }
    }
    &.cx-mobile{
        border-radius: 0px !important;
    }
    .cx-alert{
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
.cx-form-wrapper {
    .cx-form.cx-form-horizontal {
        padding: 0px 16px 20px 16px !important;
        height: fit-content !important;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 4px;
        .cx-button-group.cx-buttons-binary{
            .cx-btn{
                &.cx-btn-default{
                    &.i18n{
                        display: none !important;
                    }
                }
            }
        }
    }
    .cx-form-inputs{
        table{
            display: flex;
            flex-direction: column;
            margin-bottom: 0 !important;
            tr{
                display: contents;
                td{
                    padding: 0 !important;
                    margin-bottom: 16px !important;
                    input::placeholder{
                        color: $color-content-low !important;
                    }
                }
                th{
                    padding: 0 !important;
                    margin-bottom: 4px !important;
                }
            }
        }
    }
}

path.cx-svg-icon-tone1 {
    fill: #131D34 !important;
}
rect.cx-svg-icon-tone1 {
    fill: #131D34 !important;
}


.cx-common-container.cx-toast {
    .cx-button-container {
        padding: 14px 24px !important;
    }
}


.cx-menu-item {
    &.cx-icon {
        &.cx-branding-icon.cx-inert {
            display: none;
        }
    }
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant.cx-them:first-child div.cx-avatar-wrapper{
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant:first-child div.cx-bubble-arrow{
    display: none !important;
}
.cx-webchat .cx-transcript .cx-message-group> div.cx-message.cx-participant{
    margin-bottom: 8px !important;
}
.cx-toast{
    .cx-titlebar{
        padding: 20px 16px 16px 16px !important;
    }
}
