@mixin high-s {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -1.4px;
    font-weight: 400;
    @media only screen and (max-width: 1279px) {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -1.2px;
    }
}
%high-s {
    @include high-s;
}

@mixin mid-s {
    font-family: $font-helvetica-now-medium;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    font-weight: 500;
    @media only screen and (max-width: 1279px) {
        font-size: 14px;
        line-height: 20px;
    }
}
%mid-s {
    @include mid-s;
}

@mixin mid-l {
    font-family: $font-helvetica-now-medium;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1.4px;
    font-weight: 500;
    @media only screen and (max-width: 1279px) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1.2px;
    }
}
%mid-l {
    @include mid-l;
}

@mixin low-xs {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
    font-weight: 400;
}
%low-xs { @include low-xs; }

@mixin low-s {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 400;
}

%low-s { @include low-s; }

@mixin low-l {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.8px;
    font-weight: 400;
}

%low-l { @include low-l; }

@mixin low-m {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.6px;
    font-weight: 400;
}
%low-m { @include low-m; }

@mixin high-xl {
    font-family: $font-helvetica-now-display;
    font-size: 64px;
    line-height: 68px;
    letter-spacing: -1.6px;
    font-weight: 400;
    @media only screen and (max-width: 1279px) {
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -1px;
    }
}

%high-xl { @include high-xl; }

@mixin mid-xs {
    font-family: $font-helvetica-now-medium;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    font-weight: 500;
    @media only screen and (max-width: 1279px) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.3px;
    }
}
%mid-xs { @include mid-xs; }

.text {
    font-family: $font-helvetica-now-regular;
    color: $color-content-high;

    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-justify {
        text-align: justify;
    }

    &-bold {
        font-family: $font-helvetica-now-medium;
    }
    &-content-high {
        color: $color-content-high;
    }
    &-content-mid {
        color: $color-content-mid;
    }
    &-content-low {
        color: $color-content-low;
    }
    &-content-semantic-primary {
        color: $color-semantic-primary;
    }
    &-content-semantic-danger {
        color: $color-semantic-danger;
    }
    &-content-semantic-success {
        color: $color-semantic-success;
    }
    &-surface-white {color: $color-surface-white}

    &-hover{
        &:hover{
            opacity: 0.7;
            transition: all 0.3s ease-in-out;
        }
    }
    &.low-xs { @extend %low-xs; }

    &.low-s { @extend %low-s; }

    &.low-m { @extend %low-m; }

    &.low-l { @extend %low-l; }

    &.mid-xs { @extend %mid-xs; }

    &.mid-s {
        @extend %mid-s;
    }

    &.mid-m {
        font-family: $font-helvetica-now-medium;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1.2px;
        font-weight: 500;
        @media only screen and (max-width: 1279px) {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.4px;
        }
    }

    &.mid-l {
        @extend %mid-l;
    }

    &.high-xs {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1.2px;
        font-weight: 400;
        @media only screen and (max-width: 1279px) {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -1px;
        }
    }

    &.high-s { @extend %high-s; }

    &.high-m {
        font-family: $font-helvetica-now-display;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -0.6px;
        font-weight: 400;
        @media only screen and (max-width: 1279px) {
            font-family: $font-helvetica-now-regular;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -1.4px;
        }
    }

    &.high-l {
        font-family: $font-helvetica-now-display;
        font-size: 56px;
        line-height: 62px;
        letter-spacing: -1.4px;
        font-weight: 400;
        @media only screen and (max-width: 1279px) {
            font-size: 28px;
            line-height: 34px;
            letter-spacing: -0.6px;
        }
    }

    &.high-xl {
        @extend %high-xl;
    }
}
