// Colores refactor
$color-content-high : #131D34;
$color-content-mid : rgba(19, 29, 52, 0.65);
$color-content-low : rgba(19, 29, 52, 0.45);
$color-stroke-low : rgba(19, 29, 52, 0.10);
$color-stroke-mid : rgba(19, 29, 52, 0.16);
$color-stroke-high : rgba(19, 29, 52, 0.30);
$color-surface-blue1 : #EEF6FB;
$color-surface-blue2 : #E0F0FF;
$color-surface-blue3 : #99C1F0;
$color-surface-white: #FFF;
$color-surface-danger : #FED6CD;
$color-surface-succes : #E8F8F3;
$color-surface-alert : #FFF3E0;
$color-surface-gray2 : #E6EAF0;
$color-semantic-primary : #0080F6;
$color-semantic-danger : #D92626;
$color-semantic-success : #05A36E;
$color-semantic-warning : #EA9F2D;
$color-dark-im: #1E1642;
$color-alt : #F2F4F8;
$color-background-overlay: rgba(19, 29, 52, 0.25);
$color-background-overlay-hover: rgba(19, 29, 52, 0.5);
$color-content-oncolor-high: #FFF;
$color-content-oncolor-mid: rgba(255, 255, 255, 0.75);
$color-base : #FFF;
$color-surface-gray1 : rgba(242, 244, 248, 0.55);